.image-parallax {
    background-image: url(../../images/image-background.jpg);
    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-parallax > h3 {
    font-size: 2.4rem;
    background-color: white;
    padding: 0 15px;
    text-transform: uppercase;
}

.image-parallax > a {
    cursor: pointer;
    border: none;
    margin-top: 20px;
    font-weight: 700;
    font-size: 1.3rem;
    background-color: white;
    color: black;
    padding: 0 15px;
    text-transform: uppercase;
    box-shadow: 6px 6px red;
    transition: all 300ms;
    text-decoration: none;
}

.image-parallax > a:visited {
    color: black;
}

.image-parallax > a:hover {
    box-shadow: 0px 0px red;
    scale: 1.1;
}


@media screen and (max-width: 768px) {
    .image-parallax {
        height: 200px;
        background-size: cover;
        background-attachment: fixed;
    }

    .image-parallax > h3 {
        font-size: 1.4rem;
        line-height: 27px;
        padding: 0px;
        text-align: center;
    }

    .image-parallax > button {
        cursor: pointer;
        border: none;
        margin-top: 20px;
        font-weight: 700;
        font-size: 1rem;
        background-color: white;
        padding: 0 15px;
        text-transform: uppercase;
        box-shadow: 6px 6px red;
        transition: all 300ms;
    }
}