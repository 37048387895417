.introduction-section {
    display: flex;
}

.video-indicators {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    z-index: 2; /* Mantém as bolinhas acima do vídeo */
  }
  
  .indicator-dot {
    width: 20px;
    height: 20px;
    background-color: rgb(43, 43, 43);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .indicator-dot.active {
    background-color: red;
  }
  

.carousel-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

.introduction-section > div {
    background-image: linear-gradient(to bottom, rgb(19, 19, 19), rgba(53, 53, 53, 0.493));
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.introduction-section > div > :nth-child(2) {
    margin-top: 15rem;
    font-weight: 700;
    line-height: 3rem;
    color: black;
    font-size: 3rem;
    position: absolute;
    padding: 3px 15px;
    background-color: white;
    left: 26rem;
    top: 20rem;
}

.introduction-section > div > :nth-child(3) {
    margin-top: 15rem;
    font-weight: 700;
    line-height: 3rem;
    padding: 3px 15px;
    color: black;
    font-size: 3rem;
    position: absolute;
    background-color: white;
    left: 26rem;
    top: 23rem;
}

.introduction-section > div > :nth-child(4) {
    margin-top: 15rem;
    font-weight: 700;
    line-height: 3rem;
    padding: 3px 15px;
    color: black;
    font-size: 3rem;
    position: absolute;
    background-color: white;
    left: 26rem;
    top: 26rem;
}

.text-emphasis {
    color: red;
}

@media screen and (max-width: 1440px) {
    .introduction-section > div > :nth-child(4) {
        margin-top: 15rem;
        left: 12rem;
        top: 15rem;
    }
    
    .introduction-section > div > :nth-child(2) {
        margin-top: 15rem;
        left: 12rem;
        top: 9rem;
    }
    
    .introduction-section > div > :nth-child(3) {
        margin-top: 15rem;
        left: 12rem;
        top: 12rem;
    }
}

@media screen and (max-width: 768px) {
    .introduction-section > div > :nth-child(4) {
        font-size: 2rem;
        left: 1rem;
        top: 14rem;
        padding: 1px 7px;  
    }
    
    .introduction-section > div > :nth-child(2) {
        font-size: 2rem;
        left: 1rem;
        top: 8rem;
        padding: 1px 7px;
    }
    
    .introduction-section > div > :nth-child(3) {
        font-size: 2rem;
        left: 1rem;
        top: 11rem;
        padding: 1px 7px;
    }
}