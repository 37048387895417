.contact-section {
    display: flex;
    justify-content: center;
    margin-top: 13rem;
    width: 100vw;
}

.contact-section > :nth-child(1) {
    width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
}

.contact-section > :nth-child(1) > h3 {
    font-size: 2rem;
    margin-bottom: 3rem;
    color: rgb(34, 33, 33);
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.349);
}

.text-red {
    color: rgb(184, 16, 16);
}

.contact-section > :nth-child(1) > hr {
    width: 300px;
    height: 1px;
    background-color: rgba(34, 33, 33, 0.541);;
    border: none;
    margin-bottom: 2rem;
}

.contact-section > :nth-child(1) > p {
    text-align: center;
    width: 800px;
    letter-spacing: 2px;
    color: rgb(34, 33, 33);
}

.contact-section > :nth-child(1) > a {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 3rem;
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 1.1rem;
    background-color: rgb(37, 37, 37);
    color: rgb(201, 20, 20);
    text-decoration: none;
    padding: 5px 25px;
    text-transform: uppercase;
    box-shadow: 6px 6px rgb(179, 12, 12);
    transition: all 300ms;
}

.contact-section > :nth-child(1) > a:hover  {
    box-shadow: 0px 0px;
    padding: 10px 30px;
    scale: 1.1;
    color: rgb(224, 214, 214);
}

svg {
    margin-right: 10px;
}

svg path {
    transition: all 300ms;
    
}

.contact-section > :nth-child(1) > a:hover svg path {
    fill: #dfddd6;
  }


.contact-section > :nth-child(1) > a > img {
    width: 30px;
    margin-right: 0.6rem;
}

.contact-section > :nth-child(2) {
    display: flex;
    flex-direction: column;
    text-align: center;;
    margin-bottom: 4rem;
}

.contact-section > :nth-child(2) > :nth-child(1) {
    color: rgb(44, 42, 42);
    margin-bottom: 0.5rem;
}

.contact-section > :nth-child(2) > p {
    color: rgb(75, 73, 73);
    letter-spacing: 1.5px;
}

.contact-section > :nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.contact-section > :nth-child(3) > iframe {
    width: 800px;
    height: 400px;
    border: none;
    zoom: -20%;
}

@media screen and (max-width: 600px) {

    .contact-section {
    margin-top: 10rem;
    }

    .contact-section > :nth-child(1) > h3 {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    }

    .contact-section > :nth-child(1) {
        width: 100dvw;
    }

    .contact-section > :nth-child(1) > hr {
    width: 200px;
    }

    .contact-section > :nth-child(1) > p {
    text-align: center;
    width: 80dvw;
    letter-spacing: 1.5px;
    color: rgb(34, 33, 33);
    }

    .contact-section > :nth-child(3) > iframe {
    width: 90dvw;
    height: 30dvh;
    border: none;
    zoom: 0%;
    }
}