footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 17rem;
    background-color: rgb(44, 44, 44);
    margin-top: 3rem;
    width: 100vw;
}

footer > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 15rem;
}

footer > section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin: 0 25rem;
}

footer > section > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

footer > section > div > h4 {
    color: rgb(189, 19, 19);
    margin: 1rem;
}

footer > section > div {
    color: whitesmoke;
    margin-bottom: 1rem;
}

footer > div > p {
    color: rgb(245, 245, 245);
    font-size: 1.5rem;
}

.social-medias {
    gap: 2rem;
    display: flex;
    align-items: center;
}

.social-medias > a > img:hover {
    scale: 1.1;
}


.social-medias > a > img {
    transition: all 300ms;
    width: 40px;
}

footer > :nth-child(2) {
    height: 1px;
    background-color: rgba(102, 102, 102, 0.692);
    border-radius: 0px;
    align-self: center;
    width: 900px;
    margin: 1rem 0rem;
}
footer > :nth-child(4) {
    height: 1px;
    background-color: rgba(102, 102, 102, 0.692);
    border-radius: 0px;
    align-self: center;
    width: 100vw;
    margin: 1rem 0rem;
}

footer > p {
    color: rgba(245, 245, 245, 0.726);
    font-size: 0.9rem;
    align-self: center;
}

@media screen and (max-width: 1444px) {
    footer > section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0 15rem;
    }
}

@media screen and (max-width: 600px) {
    footer > div {
        margin: 0 0rem;
    }

    footer > section {
        margin: 0 0rem;
    }

    footer > section > div {
        width: 110px;
    }

    footer > section > div > h4 {
        margin: 0.5rem;
        font-size: 0.8rem;
    }

    footer > section > div > p {
        font-size: 0.9rem;
        margin: 0.5rem;
    }
    
    footer > section > div {
        margin-bottom: 0.5rem;
    }
    
    footer > div > p {
        font-size: 1.2rem;
    }
    
    .social-medias {
        gap: 1rem;
    }
    
    footer > :nth-child(2) {
        width: 300px;
    }
    
    footer > p {
        color: rgba(245, 245, 245, 0.726);
        font-size: 0.9rem;
        align-self: center;
    }
}

