.our-place {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 10;
    height: 60rem;
    width: 100vw;
}


.place-background {
    width: 100vw;
    height: 40rem;
    left: 0;
    top: 0;
    background-color: rgb(163, 35, 35);
    position: absolute;
    z-index: 1;
}

.our-place-section > h2 {
    width: 340px;
    padding: 0 8px;
    font-size: 4rem;
    letter-spacing: -3px;
    z-index: 5;
    color: white;
    position: absolute;
    top: 8rem;
    left: 29rem;
}

.our-place-section > :nth-child(3) > img {
    width: 1000px;
    position: absolute;
    top: 5rem;
    right: 25rem;
    z-index: 3;
}


.our-place > :nth-child(2) {
    display: flex;
    position: absolute;
    bottom: 10rem;
    left: 29rem;
}

.our-place > :nth-child(2) > img {
    width: 300px;
    margin-right: 15px;
    z-index: 7;
}

.our-place > a {
    cursor: pointer;
    z-index: 99;
    text-decoration: none;
    font-size: 1.3rem;
    background-color: rgb(58, 58, 58);
    color: rgb(255, 24, 24);
    box-shadow: 6px 6px rgb(255, 24, 24);
    text-transform: uppercase;
    font-weight: bold;
    padding: 13px;
    transition: all 300ms;
}

.our-place > a:hover {
    box-shadow: 0px 0px rgb(255, 24, 24);
    scale: 1.1;
}

@media screen and (max-width: 1440px) {
    .our-place-section  {
        height: 55rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .our-place-section > h2 {
        left: 13rem;
        font-size: 3.6rem;
    }

    .our-place-section > :nth-child(3) > img {
        width: 850px;
        position: absolute;
        top: 5rem;
        right: 10rem;
        z-index: 3;
    }

    .place-background {
        height: 35rem;
    }

    .our-place > :nth-child(2) {
        bottom: 10rem;
        left: 13rem;
    }
    
    .our-place > :nth-child(2) > img {
        width: 260px;
    }

}

@media screen and (max-width: 768px) {
    .our-place {
        flex-direction: column;
        position: relative;
    }

    .place-background {
        height: 15rem;
    }

    .our-place-section > h2 {
        width: 200px;
        font-size: 2rem;
        line-height: 1.8rem;
        top: 6rem;
        left: 0.5rem;
    }

    .our-place-section > :nth-child(3) > img {
        width: 300px;
        position: absolute;
        top: 5rem;
        left: 2.5rem;
        z-index: 3;
    }

    .our-place > :nth-child(2) {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 25rem;
        margin-bottom: 3rem;
        top: 0;
        left: 0;
    }
    
    .our-place > :nth-child(2) > img {
        width: 250px;
        margin-top: 1rem;
        z-index: 7;
    }

    .our-place > a {
        font-size: 1rem;
        margin-bottom: 3rem 0;
    }
}