.test-header {
    width: 100vw;
    z-index: 999999;
    background-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(19, 19, 19, 0.11));
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 99;
    padding: 1rem 4rem;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-container > a > img {
    width: 90px;
    opacity: 1;
}

.header-container > a {
    transition: all 300ms;
}

.header-container > a:hover {
    scale: 1.1;
}

.header-list-itens {
    display: flex;
    gap: 1rem;
}

.header-list-itens > ul {
    align-items: center;
    display: flex;
    gap: 1rem;
    list-style: none;
    font-weight: bold;
}

.header-list-itens > ul > li {
    padding: 2rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    color: rgb(212, 212, 212);
    position: relative;
}

.header-list-itens > ul > li > a {
    text-decoration: none;
    color: rgb(212, 212, 212);
}

.header-list-itens > ul > li > a:visited {
    color: rgb(212, 212, 212);
}

.header-list-itens > ul > li::after {
    content: '';
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    width: 0px;
    height: 0.3rem;
    background: red;
    transition: all 150ms ease-in-out;
    border-radius: 10px;
}

.header-list-itens > ul > li:hover::after {
    width: 50%;
    left: 25%;
}

.header-list-itens > div > button {
    background-color: transparent;
    border: none;
    color: red;
    font-size: 1rem;
    font-weight: bolder;
}

.header-list-itens > div > button:hover {
    color: white;
    cursor: pointer;
    transition: 400ms;
}

.header-list-itens > div {
    display: flex;
    align-items: center;    
}

.buguer-menu {
    width: 1.8rem;
    height: 1.3rem;
}

.content-burguer {
    background-color: rgb(34, 34, 34);
    padding: 0.5rem;
    border-radius: 5px;
}

@media screen and (max-width: 1440px) {
    .header-list-itens > ul > li {
        padding: 1rem 0.3rem;
        font-size: 1rem;
    }

    .header-container {
        padding: 1rem 8rem;
    }

    .header-container > img {
        width: 70px;
        opacity: 1;
    }
}


@media screen and (max-width: 768px) {

    .test-header {
        padding: 0;
        position: fixed;
        display: flex;
    }

    .header-container {
        height: 7rem;
        display: flex;
        padding: 0rem;
    }

    .content-burguer {
        position: fixed;
        height: 2.3rem;
        top: 2rem;
        right: 2rem;
        z-index: 99;
    }

    header > div {
        height: 2rem;
    }

    .header-list-itens > ul {
        flex-direction: column;
        background-color: rgb(34, 34, 34);
        padding: 1.5rem;
        margin-top: 2rem;
    }

    nav {
        padding: 0.3rem;
        position: absolute;
        top: 0rem;
        right: 2rem;
    }
}

