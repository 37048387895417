.user-home {
    display: flex;
}

.user-section-header {
    width: 95vw;
}

.user-home > aside {
    height: 100vh;
    width: 6vw;
    background-color: rgb(231, 231, 231);
    border-left: 3px solid #C50F0F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    right: 0px;
    position: fixed;
}

.music-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.music-list {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.music-list > form {
    margin-top: 8rem;
    display: flex;
    flex-direction: row;
    width: 60vw;
    justify-content: center;
}

.music-list > form > label {
    width: fit-content;
}

.music-list > form > label > input {
    width: fit-content;
    border-radius: 19px 0 0 19px;
    border: 2px solid black;
    padding: 7px;
    background-color: white;
}

.music-list > form > label > button {
    margin-left: -2px;
    border-radius: 0 19px 19px 0;
    border: 2px solid black;
    padding: 7px;
    background-color: white;
}

.music-list > form > select {
    width: 6rem;
    border-radius: 19px;
    border: 2px solid black;
    padding: 7px;
    background-color: white;
}

.music-list > div {
    margin-top: 3rem;
}

.music-list > div > a {
    justify-content: space-between;
    margin-top: 10px;
    width: 40vw;
    border: 1px solid black;
    padding: 5px;
    display: flex;
    border-radius: 7px;
}

.logo-music{
    width: 25px;
}

.logo-icon {
    width: 5.8vw;
}

.aside-icon {
    width: 50px;
    margin-top: 10px;
}

aside > div > p {
    color: #C50F0F;
    font-size: 12px;
    font-weight: 700;
}

aside > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-music {
    text-decoration: none;
}

.music-list-item > p {
    color: rgb(83, 83, 83);
}

.music-list-item > p:nth-child(2)  {
    color: rgb(207, 0, 0);
    font-weight: bold;
}

.music-list-item {
    display: flex;
    justify-content: space-between;
    width: 380px;
}
