.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: whitesmoke;
}

.login-content {
    height: 400px;
    position: relative;
    width: 600px;
    border-radius: 20px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.438);
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.login-content > div > h2 {
    font-size: 3rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.login-image{
    height: 400px;
    width: 300px;
    background-image: url('../../images/login-image.jpg');
    background-size: cover;
    background-position: center;
    border-radius: 0 20px 20px 0px;
    border-left: 2px solid rgb(212, 20, 20);
}

.teste {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: fit-content;
}

label {
    position: relative;
}

.email-label {
    position: absolute;
    background-color: white;
    color: red;
    border-radius: 5px;
    padding: 0 9px;
    top: -16px;
    left: 15px;
}

.password-label {
    position: absolute;
    color: red;
    background-color: white;
    border-radius: 5px;
    padding: 0 9px;
    top: -16px;
    left: 15px;
}

.input-login {
    border-radius: 19px;
    border: 2px solid black;
    padding: 7px;
}

.error-message {
    text-align: center;
    width: 250px;
}

form > button {
    height: 2rem;
    background-color: black;
    color: white;
    font-weight: 800;
    letter-spacing: 1.5px;
    font-size: 1rem;
    border-color: transparent;
}

form > button:hover {
    color: red;
    background-color: white;
    border-color: red;
    transition: 300ms;
}