.events-container {
    display: flex;
    width: 100vw;
    align-items: flex-start;
}

.events-container > h3 {
    margin: auto;
    margin-top: 15rem;
    font-size: 3rem;
    display: flex;
    text-shadow: 0px 2px 2px white;
    letter-spacing: -3px;
    text-transform: uppercase;  
}

.events-container > :nth-child(3) {
    position: static !important;
    width: 270px;
    height: 30px;
    background-color: rgb(160, 13, 13);
    margin: auto;
    margin-top: -1.7rem;
    z-index: -99;
    margin-bottom: 6rem;
}

.events-list {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
}

.event-item {
    display: flex;
    flex-direction: row;
    width: 70vw;
    padding: 1rem 0rem;
    transition: 500ms;
    background: transparent;
}

.event-item:hover {
    cursor: pointer;
    scale: 1.03;
}

.event-item > div hr {
    transition: all 200ms ease-in-out;
}

.event-item:hover > div hr {
    cursor: pointer;
    width: 400px;
}

.event-item:hover > div > :nth-child(4) {
    color: rgb(160, 13, 13)
}

.event-item > img {
    width: 230px;
}

.event-item > div {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.event-item > div > div {
    display: flex;
    justify-content: space-between;
}

.event-item > div > div > h4 {
    font-size: 1.3rem;
    padding: 5px;
    color: rgb(170, 15, 15);
}

.event-item > div > hr {
    border: none;
    width: 200px;
    height: 1px;
    background-color: rgb(59, 56, 56);
    margin: auto;
    margin-bottom: 1rem;
}

.event-item > div > :nth-child(3) {
    margin-bottom: 1rem;
}

.event-item > div > :nth-child(4) {
    color: rgb(82, 80, 80);
    letter-spacing: 2px;
}

@media screen and (max-width: 600px) {
    .events-container > h3 {
        margin-top: 12rem;
        font-size: 2.5rem;
    }
    
    .events-container > :nth-child(3) {
        width: 175px;
        height: 20px;
        margin-top: -1.4rem;
    }

    .event-item {
        width: 90vw;
        padding: 0rem 0rem;
        align-items: center;
    }

    .event-item:hover > div hr {
        cursor: pointer;
        width: 200px;
    }

    .event-item > img {
        width: 100px;
        height: 100px;
    }

    .event-item > div > div > h4 {
        font-size: 1rem;
    }

    .event-item > div > hr {
        width: 100px;
    }

    .event-item > div > :nth-child(3) {
        margin-bottom: 1rem;
        font-size: 0.9rem;
    }
    

    .event-item > div > :nth-child(4) {
        font-size: 1rem;
        letter-spacing: 1px;
    }
}