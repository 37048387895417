section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.music-content {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
}

.music-content > h3 {
    font-size: 3rem;
    letter-spacing: -6px;
    font-family: 'Montserrat', sans-serif;
}

.music-content > p {
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: rgb(88, 88, 88);
}

.btn-back {
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    color: red;
    font-weight: 800;
    letter-spacing: 1.5px;
    font-size: 1rem;
    text-decoration: none;
}

.btn-back:visited {
    color: red;
}

.btn-back:hover {
    color: rgb(143, 0, 0);
    transition: 300ms;
}
