.cursos-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10rem;
    width: 100vw;
}


.cursos-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 75vw;
    height: max-content;
    perspective: 1000px
}

.cursos-section > div {
    cursor: pointer;
}

.title-cursos {
    position: absolute;
    left: 15rem;
    top: -4rem;
}

.title-cursos > h2 {
    text-shadow: 0px 2px 2px white
}

.line-red {
    position: absolute;
    width: 12.4rem;
    height: 25px;
    background-color: red;
    left: 0rem;
    top: 3rem;
    z-index: -1;
    animation: line 3s;
}

.hidden {
    opacity: 0;
    width: 1000px;
}

@keyframes line {
    from { width: 0rem };
}


.cursos-section > div {
    width: 350px;
    height: 300px;
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 3rem;
}

.cursos-section > div > img {
    width: 300px;
    position: absolute;
    left: -10px;
    top: 1rem;
}

.cursos-section > div > div {
    display: block;
    position: relative;
}

.cursos-section > div > div > h2 {
    margin: 0;
    width: 14rem;
    position: relative;
    color: black;
    font-size: 3rem;
    z-index: 99;
    text-shadow: 2px 2px 2px white;
}

.cursos-section > div > div > div {
    width: 100px;
    height: 10px;
    position: absolute;
    background-color: rgb(230, 13, 13);
    left: 0;
    top: 50px;
}

.cursos-section > div >:nth-child(2) {
    z-index: 5;
    left: 0px;
    rotate: 76deg;
    top: 1rem;
}

.cursos-section > div >:nth-child(3) {
    z-index: 10;
}

.cursos-section > div >:nth-child(1) {
    z-index: 15;
}

.cursos-content > a {
    cursor: pointer;
    border: none;
    margin-top: 60px;
    font-weight: 700;
    font-size: 1.3rem;
    background-color: rgb(58, 58, 58);
    color: rgb(230, 13, 13);
    text-decoration: none;
    padding: 10px 15px;
    text-transform: uppercase;
    box-shadow: 6px 6px rgb(230, 13, 13);
    transition: all 300ms;
    margin-bottom: 3rem;
}

.cursos-content > a:hover {
    box-shadow: 0px 0px red;
    scale: 1.1;
}

@media screen and (max-width: 1900px) {
    .cursos-section {
        width: 85vw;
        height: max-content;
    }

    .cursos-section > div {
        margin: 3rem;
    }
}

@media screen and (max-width: 1440px) {
    .cursos-section > div {
        margin: 2rem;
        width: 300px;
        height: 250px;
    }

    .cursos-section > div > img {
        width: 250px;
    }   

    .cursos-section > div > div > h2 {
        font-size: 2.8rem;
    }

    .cursos-section {
        height: max-content;
    }
}

@media screen and (max-width: 600px) {
    .cursos-section {
        width: 100vw;
        height: max-content;
    }
    
    .title-cursos {
        left: 2rem;
    }

    .title-cursos > div {
        width: 8.2rem;
        top: 2rem;
    }

    .cursos-section > div {
        width: 150px;
        height: 150px;
        margin: 1.5rem;
    }

    
    .cursos-section > div > img {
        width: 180px;
        left: -20px;
    }

    .cursos-section > div > div > h2 {
        font-size: 2rem;
    }

    .cursos-section > div > div > div {
        width: 60px;
        height: 8px;
        right: -3px;
        top: 33px;
    }

    .cursos-section > div >:nth-child(2) {
        left: -10px;
        rotate: 15deg;
        top: 1rem;
    }
    
    .cursos-section > div >:nth-child(3) {
        z-index: 10;
    }
    
    .cursos-section > div >:nth-child(1) {
        z-index: 15;
    }

    
}