@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&family=Montserrat:ital,wght@0,400;0,700;1,700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  #root {
    min-width: max-content;
  }
  
  ::-webkit-scrollbar{
    display: none;
  }

  .stroke-text {
    color: transparent;
    letter-spacing: 0px;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: red;
  }
  