main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    width: 100vw;
    position: relative;
}

h2 {
    font-size: 4rem;
    letter-spacing: -6px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 4rem;
}

.fundo-imagem {
    position: absolute;
    left: 10rem;
    top: 9rem;
    transform: rotate(40deg);
    z-index: -1;
    transition: all 300ms; 
    pointer-events: auto;
}

.fundo-imagem3 {
    position: absolute;
    right: 28rem;
    top: -5rem;
    rotate: -60deg;
    z-index: -10;
}

.fundo-imagem3 > img {
    width: 200px;
}

.fundo-imagem4 {
    position: absolute;
    left: 3rem;
    top: 65rem;
    rotate: -20deg;
    z-index: -10;
}

.fundo-imagem4 > img {
    width: 200px;
}

.fundo-imagem2 {
    position: absolute;
    right: 5rem;
    top: 24rem;
    rotate: -100deg;
    z-index: -10;
}

.fundo-imagem2 > img {
    width: 500px;
}

.fundo-imagem > img {
    width: 400px;
}

main > div {
    display: flex;
    gap: 2rem;
}

main > div > img {
    width: 300px;
}

.text-section-home {
    display: flex;
    flex-direction: column;
}

.text-section-home > p {
    width: 600px;
    letter-spacing: 1.5px;
    line-height: 2rem;
    margin-bottom: 1rem;
}

.text-section-home > h2 {
    font-size: 3rem;
    letter-spacing: -4px;
}

@media screen and (max-width: 1440px) {
    .fundo-imagem {
        left: 1rem;
        top: 5rem;
    }
    .fundo-imagem > img {
        width: 300px;
    }

    .fundo-imagem2 {
        right: 2rem;
        top: 24rem;
    }
    .fundo-imagem2 > img {
        width: 300px;
    }

    .fundo-imagem3 {
        right: 11rem;
        top: -5rem;
    }
    .fundo-imagem3 > img {
        width: 200px;
    }

    .fundo-imagem4 {
        left: 30rem;
        top: 33rem;
    }
    .fundo-imagem4 > img {
        width: 250px;
    }
}

@media screen and (max-width: 600px) {
    .section-home {
        display: flex;
        flex-direction: column;
    }

    .fundo-imagem, .fundo-imagem2, .fundo-imagem3, .fundo-imagem4 {
        display: none;
    }

    h2 {
        font-size: 2.8rem;
        margin-bottom: 2rem;
    }

    .text-section-home > p {
        width: 350px;
        letter-spacing: 1.2px;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }
    
    .text-section-home > h2 {
        font-size: 2.3rem;
        letter-spacing: -4px;
    }

    .text-section-home {
        align-items: center;
    }
}