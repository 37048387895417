.maps {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100vw;
    gap: 10rem;
    position: relative;
}

.maps > div {
    display: flex;
    flex-direction: column;
}

.maps > div > h3 {
    color: #ce100d;
    font-weight: bolder;
    font-size: 3rem;
    letter-spacing: -1px;
    width: 400px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.493);
}

.maps > div > img {
    width: 500px;
}

.maps > img {
    position: absolute;
    width: 230px;
    left: 58rem;
    bottom: 8rem;    
}

.maps > iframe {
    width: 400px;
    height: 500px;
    border: 0px solid black;
    box-shadow: 10px 10px #a02321;
}

@media screen and (max-width: 1680px) {
    .maps > img {
        left: 50rem;  
    }
}

@media screen and (max-width: 1440px) {
    .maps > img {
        left: 42rem;  
    }
}

@media screen and (max-width: 1366px) {
    .maps > img {
        width: 200px;
        left: 40rem;
        bottom: 5rem; 
    }

    .maps > div > img {
        width: 400px;
    }

    .maps > iframe {
    width: 300px;
    height: 400px;
    margin-top: 4rem;
    }
}

@media screen and (max-width: 600px) {
    .maps {
        margin-top: 6rem;
        flex-direction: column;
        gap: 1rem;
    }

    .maps > div > img {
        width: 350px;
    }

    .maps > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .maps > div > h3 {
        font-size: 2.5rem;
        width: 350px;
        line-height: 2.5rem;
    }

    .maps > img {
        position: absolute;
        width: 200px;
        rotate: 100deg;
        left: 8rem;
        top: 21rem;    
    }
}