.teachers-section {
    display: flex;
    flex-direction: column;
    position: relative;
}

.teachers-section > h3 {
    align-self: flex-start;
    margin-left: 30rem;
    margin-top: 6rem;
    text-transform: uppercase;
    text-shadow: 0px 2px 2px white;
    font-size: 2.5rem;
}

.teachers-section > :nth-child(2) {
    width: 280px;
    height: 40px;
    background-color: #ce100d;
    position: absolute;
    left: 32.5rem;
    top: 7.5rem;
    z-index: -1;
}

.teachers-section > :nth-child(3) {
    display: flex;
    margin-top: 5rem;
    align-items: center;
    gap: 2rem;
}

.teachers-section > :nth-child(3) > img {
    height: 60px;
    cursor: pointer;
    transition: all 200ms;
}

.teachers-section > :nth-child(3) > img:hover {
    scale: 1.1;
}

.teachers-section > :nth-child(3) > :nth-child(1) {
    rotate: 180deg;
}

.teacher-display {
    display: flex;
}

.teacher {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
}

.teacher > img {
    width: 200px;
}

.teacher > h4 {
    color: #ce100d;
    font-size: 1.3rem;
}

.teacher > p {
    color: rgb(65, 65, 65);
    letter-spacing: 2px;
}

.teachers-change {
    display: flex;
    margin-bottom: 5rem;
    gap: 0.4rem;
}

.teachers-section > a {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.3rem;
    background-color: rgb(58, 58, 58);
    color: rgb(211, 17, 17);
    box-shadow: 6px 6px rgb(201, 13, 13);
    text-transform: uppercase;
    font-weight: bold;
    padding: 13px;
    transition: all 300ms;
}

.teachers-section > a:hover {
    box-shadow: 0px 0px rgb(199, 17, 17);
    scale: 1.1;
}

@media screen and (max-width: 1440px) {
    .teachers-section > h3 {
        margin-left: 10rem;
    }
    
    .teachers-section > :nth-child(2) {
        left: 12.5rem;
    }
}

@media screen and (max-width: 600px) {
    .teachers-section > h3 {
        margin-left: 3rem;
        margin-top: 10rem;
        font-size: 2rem;
    }

    .teachers-section > :nth-child(2) {
        width: 200px;
        height: 30px;
        left: 6rem;
        top: 11.5rem;
    }

    .teachers-section > :nth-child(3) {
        display: flex;
        margin-top: 2rem;
        gap: 0rem;
    }

    .teacher {
        display: flex;
        flex-direction: column;
        padding: 0rem;
        align-items: center;
    }
    
    .teacher > img {
        width: 140px;
    }
    
    .teacher > h4 {
        color: #ce100d;
        font-size: 1.3rem;
    }
    
    .teacher > p {
        color: rgb(65, 65, 65);
        letter-spacing: 2px;
    }

    .teacher-display {
        display: flex;
        flex-direction: column;
        margin: 0 3rem;
    }
    
    .teachers-section > :nth-child(3) > img {
        height: 40px;
    }
    
    .teachers-change {
        display: flex;
        margin-bottom: 4rem;
        margin-top: 1rem;
        gap: 1rem;
    }
}
